import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// components
import SvgColor from 'src/components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
    <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
    // OR
    // <Iconify icon="fluent:mail-24-filled" />
    // https://icon-sets.iconify.design/solar/
    // https://www.streamlinehq.com/icons
);

const ICONS = {
    job: icon('ic_job'),
    blog: icon('ic_blog'),
    chat: icon('ic_chat'),
    mail: icon('ic_mail'),
    user: icon('ic_user'),
    file: icon('ic_file'),
    lock: icon('ic_lock'),
    tour: icon('ic_tour'),
    order: icon('ic_order'),
    label: icon('ic_label'),
    blank: icon('ic_blank'),
    kanban: icon('ic_kanban'),
    folder: icon('ic_folder'),
    banking: icon('ic_banking'),
    booking: icon('ic_booking'),
    invoice: icon('ic_invoice'),
    product: icon('ic_product'),
    calendar: icon('ic_calendar'),
    disabled: icon('ic_disabled'),
    external: icon('ic_external'),
    menuItem: icon('ic_menu_item'),
    ecommerce: icon('ic_ecommerce'),
    analytics: icon('ic_analytics'),
    dashboard: icon('ic_dashboard'),
    clientela: icon('ic_clientela'),
};

// ----------------------------------------------------------------------

export function useNavData() {
    const data = useMemo(
        () => [
            // OVERVIEW
            // ----------------------------------------------------------------------
            {
                subheader: 'aplicación v0.6',
                items: [
                    {
                        title: 'app',
                        path: paths.dashboard.root,
                        icon: ICONS.dashboard,
                    },
                ],
            },
            // GESTIÓN
            // ----------------------------------------------------------------------
            {
                subheader: 'gestión',
                items: [
                    {
                        title: 'servicios',
                        path: paths.dashboard.servicios.root,
                        icon: ICONS.kanban,
                    }
                ],
            },

            // RECURSOS HUMANOS
            // ----------------------------------------------------------------------
            {
                subheader: 'recursos humanos',
                items: [
                    // PERSONAL
                    {
                        title: 'personal',
                        path: paths.dashboard.personal.root,
                        icon: ICONS.user,
                        children: [
                            { title: 'listar personal', path: paths.dashboard.personal.list },
                            { title: 'crear personal', path: paths.dashboard.personal.new },
                        ],
                    },
                    // PERSONAS USUARIAS
                    {
                        title: 'personas usuarias',
                        path: paths.dashboard.clientela.root,
                        icon: ICONS.clientela,
                        children: [
                            { title: 'listar personas usuarias', path: paths.dashboard.clientela.list },
                            { title: 'crear persona', path: paths.dashboard.clientela.new },
                        ],
                    },
                ],
            },
        ],
        []
    );

    return data;
}
