export const provincias = [
    { label: '' },
    { label: 'Álava' }, 
    { label: 'Albacete' }, 
    { label: 'Alicante' }, 
    { label: 'Almería' }, 
    { label: 'Asturias' }, 
    { label: 'Ávila' },
    { label: 'Badajoz' }, 
    { label: 'Barcelona' }, 
    { label: 'Burgos' }, 
    { label: 'Cáceres' }, 
    { label: 'Cádiz' }, 
    { label: 'Cantabria' },
    { label: 'Castellón' }, 
    { label: 'Ceuta' }, 
    { label: 'Ciudad Real' }, 
    { label: 'Córdoba' }, 
    { label: 'Cuenca' }, 
    { label: 'Gerona' }, 
    { label: 'Granada' },
    { label: 'Guadalajara' }, 
    { label: 'Guipúzcoa' }, 
    { label: 'Huelva' }, 
    { label: 'Huesca' }, 
    { label: 'Jaén' }, 
    { label: 'La Coruña' },
    { label: 'La Rioja' }, 
    { label: 'Las Palmas' }, 
    { label: 'León' }, 
    { label: 'Lérida' }, 
    { label: 'Lugo' }, 
    { label: 'Madrid' }, 
    { label: 'Málaga' },
    { label: 'Melilla' }, 
    { label: 'Murcia' }, 
    { label: 'Navarra' }, 
    { label: 'Orense' }, 
    { label: 'Palencia' }, 
    { label: 'Pontevedra' }, 
    { label: 'Salamanca' },
    { label: 'Santa Cruz de Tenerife' }, 
    { label: 'Segovia' }, 
    { label: 'Sevilla' }, 
    { label: 'Soria' }, 
    { label: 'Tarragona' },
    { label: 'Teruel' }, 
    { label: 'Toledo' }, 
    { label: 'Valencia' }, 
    { label: 'Valladolid' }, 
    { label: 'Vizcaya' }, 
    { label: 'Zamora' }, 
    { label: 'Zaragoza' }
];