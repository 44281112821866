import React from 'react';
import { Stack, Box, Link } from '@mui/material';

const Sponsors = () => (
    <Stack spacing={3} sx={{ width: '850px !important' }}>
        <Box display="flex" justifyContent="center" alignItems="center">
            <Link href="https://cecoec.es/" underline="none" target="_blank" rel="noopener noreferrer">
                <Box
                    display="flex"
                    component="img"
                    src="/assets/logos/logo-cecoec.svg"
                    alt="Logotipo CECOEC"
                    sx={{ width: 200, height: 64, cursor: 'pointer' }}
                />
            </Link>
        </Box>
        <Stack
            direction="row"
            justifyContent="space-between"
            sx={{
                borderTop: '1px solid #ccc', // Borde superior
                borderBottom: '1px solid #ccc', // Borde inferior
                py: 2, // Espacio vertical para que el contenido no esté demasiado pegado a los bordes
            }}
        >
            <Link
                href="https://next-generation-eu.europa.eu/index_es"
                underline="none"
                target="_blank"
                rel="noopener noreferrer"
            >
                <Box
                    component="img"
                    src="/assets/logos/logo-next-generation.svg"
                    alt="Logotipo Next Generation UE"
                    sx={{ width: 150, height: 48, cursor: 'pointer' }}
                />
            </Link>
            <Link
                href="https://planderecuperacion.gob.es/"
                underline="none"
                target="_blank"
                rel="noopener noreferrer"
            >
                <Box
                    component="img"
                    src="/assets/logos/logo-plan-recuperacion.svg"
                    alt="Logotipo Plan de Recuperación y Resiliencia Gobierno de España"
                    sx={{ width: 150, height: 48, cursor: 'pointer' }}
                />
            </Link>
            <Link
                href="https://www.mdsocialesa2030.gob.es/index.htm"
                underline="none"
                target="_blank"
                rel="noopener noreferrer"
            >
                <Box
                    component="img"
                    src="/assets/logos/logo-ministerio-asuntos-sociales.svg"
                    alt="Logotipo Ministerio de Derechos Sociales y Agenda 2030"
                    sx={{ width: 150, height: 48, cursor: 'pointer' }}
                />
            </Link>
            <Link
                href="https://socialasturias.asturias.es/"
                underline="none"
                target="_blank"
                rel="noopener noreferrer"
            >
                <Box
                    component="img"
                    src="/assets/logos/logo-consejeria-asuntos-sociales.svg"
                    alt="Logotipo Consejería Derechos Sociales y Bienestar"
                    sx={{ width: 150, height: 48, cursor: 'pointer' }}
                />
            </Link>
            <Box
                component="img"
                src="/assets/logos/logo-camaras-asturias.svg"
                alt="Logotipo Camaras de Asturias"
                sx={{ width: 150, height: 48, cursor: 'pointer' }}
            />
        </Stack>
    </Stack>
);
export default Sponsors;