import PropTypes from 'prop-types';
import { useEffect, useReducer, useCallback, useMemo } from 'react';
// utils
// import axios, { endpoints } from 'src/utils/axios';
import { useMutation, useQuery, useApolloClient } from '@apollo/client';
import { LOGIN_MUTATION, LOGOUT_MUTATION, REFRESH_TOKEN_MUTATION } from 'src/graphql/mutations/auth';
import { ME } from 'src/graphql/queries/me';
//
import { AuthContext } from './auth-context';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const initialState = {
    user: null,
    loading: true,
};

const reducer = (state, action) => {
    if (action.type === 'INITIAL') {
        return {
            loading: false,
            user: action.payload.user,
        };
    }
    if (action.type === 'LOGIN') {
        return {
            ...state,
            user: action.payload.user,
        };
    }
    if (action.type === 'REGISTER') {
        return {
            ...state,
            user: action.payload.user,
        };
    }
    if (action.type === 'LOGOUT') {
        return {
            ...state,
            user: null,
        };
    }
    return state;
};

// ----------------------------------------------------------------------

export function AuthProvider({ children }) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const { data: userData } = useQuery(ME);
    const [authenticateUser] = useMutation(LOGIN_MUTATION);
    const [logoutMutation] = useMutation(LOGOUT_MUTATION);
    const [refreshAccessTokenMutation] = useMutation(REFRESH_TOKEN_MUTATION);

    // cliente de apollo para controlar la cache
    const client = useApolloClient();

    /* const initialize = useCallback(async () => {
    try {
      const accessToken = sessionStorage.getItem(STORAGE_KEY);

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

        const response = await axios.get(endpoints.auth.me);

        const { user } = response.data;

        dispatch({
          type: 'INITIAL',
          payload: {
            user,
          },
        });
      } else {
        dispatch({
          type: 'INITIAL',
          payload: {
            user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'INITIAL',
        payload: {
          user: null,
        },
      });
    }
  }, []); */

    useEffect(() => {
        if (userData && userData.me) {
            dispatch({
                type: 'INITIAL',
                payload: {
                    user: userData.me,
                },
            });
        } else {
            // Handle unauthenticated user here
            dispatch({
                type: 'INITIAL',
                payload: {
                    user: null,
                },
            });
        }
    }, [userData]);

    // LOGIN
    const login = useCallback(
        async (email, password) => {
            try {
                const response = await authenticateUser({ variables: { email, password } });
                if (response.data.autenticarUsuario.success) {
                    localStorage.setItem('authToken', response.data.autenticarUsuario.authToken);
                    localStorage.setItem('refreshToken', response.data.autenticarUsuario.refreshToken);
                    dispatch({
                        type: 'LOGIN',
                        payload: {
                            user: response.data.autenticarUsuario.usuario, // Ajustar según la respuesta del servidor
                        },
                    });
                    return null;
                } 
                return response.data.autenticarUsuario.message;        
            } catch (error) {
                console.error(error.message);
                return error.message;
            }
        },
        [authenticateUser]
    );

    // REGISTER
    /* const register = useCallback(async (email, password, firstName, lastName) => {
    const data = {
      email,
      password,
      firstName,
      lastName,
    };

    const response = await axios.post(endpoints.auth.register, data);

    const { accessToken, user } = response.data;

    sessionStorage.setItem(STORAGE_KEY, accessToken);

    dispatch({
      type: 'REGISTER',
      payload: {
        user,
      },
    });
  }, []); */

    // LOGOUT
    const logout = useCallback(async () => {
        try {
            const { data } = await logoutMutation();

            if (data.logout.success) {        
                localStorage.removeItem('authToken');
                localStorage.removeItem('refreshToken');
                // Si el logout fue exitoso en el servidor, actualizar el estado en el cliente y borrar la cache de apollo                
                await client.clearStore();

                dispatch({
                    type: 'LOGOUT',
                });
            } else {
                console.error("Error during logout:", data.logout.message);
            }
        } catch (error) {
            console.error("Error during logout:", error.message);
        }
    }, [logoutMutation, client]);

    const refreshToken = useCallback(async () => {
        const storedRefreshToken = localStorage.getItem("refreshToken");
        if (storedRefreshToken) {
            try {
                const { data } = await refreshAccessTokenMutation({ variables: { refreshToken: storedRefreshToken } });
                if (data.refreshAccessToken.success) {
                    // Si el refresco fue exitoso, guardar el nuevo token de acceso en el almacenamiento local
                    localStorage.setItem("authToken", data.refreshAccessToken.authToken);
                } else {
                    console.error("Error refreshing token:", data.refreshAccessToken.message);
                }
            } catch (error) {
                console.error("Error during token refresh:", error.message);
            }
        }
    }, [refreshAccessTokenMutation]);

    // ----------------------------------------------------------------------

    const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

    const status = state.loading ? 'loading' : checkAuthenticated;

    const memoizedValue = useMemo(
        () => ({
            user: state.user,
            method: 'jwt',
            loading: status === 'loading',
            authenticated: status === 'authenticated',
            unauthenticated: status === 'unauthenticated',
            //
            login,
            logout,
        }),
        [login, logout, state.user, status]
    );

    return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
    children: PropTypes.node,
};
