// routes
import { paths } from 'src/routes/paths';
// components
import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

export const navConfig = [
    {
        title: 'Inicio',
        icon: <Iconify icon="solar:home-2-bold-duotone" />,
        path: '/',
    },  
    /* {
        title: 'Sobre nosotros',
        icon: <Iconify icon="solar:atom-bold-duotone" />,
        path: paths.about,
    }, */      
    
];
