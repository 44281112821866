import { gql } from '@apollo/client';

export const LOGIN_MUTATION = gql`
  mutation Login($email: String!, $password: String!) {
    autenticarUsuario(input: { email: $email, password: $password }) {
      success
      message
      usuario {
        id
        empresa_id
        tipo
        informacionPersonal {
          nombre
          apellidos
        }
        credenciales {
          email
        }
      }
      authToken
      refreshToken
    }
  }
`;

export const REFRESH_TOKEN_MUTATION = gql`
  mutation RefreshAccessToken($refreshToken: String!) {
    refreshAccessToken(refreshToken: $refreshToken) {
      success
      message
      authToken
    }
  }
`;

export const ESTABLECER_NUEVA_CONTRASENA = gql`
  mutation EstablecerNuevaContrasena($input: NuevaContrasenaInput) {
    establecerNuevaContrasena(input: $input) {
      success
      message
    }
  }
`;

export const LOGOUT_MUTATION = gql`
  mutation Logout {
    logout {
      success
      message
    }
  }
`;
