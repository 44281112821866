import { gql } from '@apollo/client';

export const ME = gql`
  query me {
    me {
      id
      empresa_id
      tipo
      informacionPersonal {
        nombre
        apellidos
      }
      credenciales {
        email
      }
    }
  }
`;
