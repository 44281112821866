export const parentescos = [
    { label: '' },
    { label: 'CONYUGE' },
    { label: 'PADRE' },
    { label: 'MADRE' },
    { label: 'HERMANO' },
    { label: 'HERMANA' },
    { label: 'HIJO' },
    { label: 'HIJA' },
    { label: 'YERNO' },
    { label: 'NUERA' },
    { label: 'ABUELO' },
    { label: 'ABUELA' },
    { label: 'NIETO' },
    { label: 'NIETA' },
    { label: 'TIO' },
    { label: 'TIA' },   
    { label: 'SOBRINO' },
    { label: 'SOBRINA' },
    { label: 'OTRO' },
];
