import { ApolloClient, InMemoryCache, ApolloLink, Observable } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from '@apollo/client/link/context';
import { REFRESH_TOKEN_MUTATION } from 'src/graphql/mutations/auth';

// Link para gestionar la autenticación
const authLink = setContext((_, { headers }) => {
    // Obtener el token del almacenamiento local
    const token = localStorage.getItem('authToken');
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : ""
        }
    };
});

const uploadLink = createUploadLink({
    uri: process.env.REACT_APP_GRAPHQL_API, // Tu endpoint de GraphQL
    credentials: 'include', // Asegura que las cookies se envíen con la solicitud
});

const customErrorLink = new ApolloLink((operation, forward) => 
    new Observable(observer => {
        const subscription = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: async networkError => {
                if (networkError && networkError.result && networkError.result.errors) {
                    const refreshTokenError = networkError.result.errors.some(
                        err => err.message === "Context creation failed: Token has expired"
                    );
                    if (refreshTokenError) {
                        const refreshToken = localStorage.getItem("refreshToken");
                        if (refreshToken) {
                            // reestablecer el token de autenticación antes de refrescar
                            localStorage.removeItem("authToken");
                            try {
                                const response = await client.mutate({
                                    mutation: REFRESH_TOKEN_MUTATION,
                                    variables: { refreshToken }
                                });
                                if (response.data.refreshAccessToken.success) {
                                    localStorage.setItem("authToken", response.data.refreshAccessToken.authToken);
                                    subscription.unsubscribe();
                                    forward(operation).subscribe(observer);
                                } else {
                                    console.error(response.data.refreshAccessToken.message);
                                    observer.error(networkError);
                                }
                            } catch (error) {
                                console.error(error);
                                observer.error(networkError);
                            }
                        } else {
                            observer.error(networkError);
                        }
                    } else {
                        observer.error(networkError);
                    }
                } else {
                    observer.error(networkError);
                }
            },
            complete: observer.complete.bind(observer)
        });
        return () => {
            if (subscription) subscription.unsubscribe();
        };
    })
);


const client = new ApolloClient({
    link: customErrorLink.concat(authLink).concat(uploadLink),
    cache: new InMemoryCache(),
});

export default client;
