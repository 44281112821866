import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

const IndexPage = lazy(() => import('src/pages/dashboard/app'));
// PERSONAL
const PersonalListPage = lazy(() => import('src/pages/dashboard/personal/list'));
const PersonalCreatePage = lazy(() => import('src/pages/dashboard/personal/new'));
const PersonalEditPage = lazy(() => import('src/pages/dashboard/personal/edit'));
const PersonalCalendarPage = lazy(() => import('src/pages/dashboard/personal/calendar'));
const PersonalDetailPage = lazy(() => import('src/pages/dashboard/personal/detail'));
// CLIENTELA
const ClientelaListPage = lazy(() => import('src/pages/dashboard/clientela/list'));
const ClientelaCreatePage = lazy(() => import('src/pages/dashboard/clientela/new'));
const ClientelaEditPage = lazy(() => import('src/pages/dashboard/clientela/edit'));
const ClientelaCalendarPage = lazy(() => import('src/pages/dashboard/clientela/calendar'));
const ClientelaDetailPage = lazy(() => import('src/pages/dashboard/clientela/detail'));
// TAREAS
const ClientelaTareasCreatePage = lazy(() => import('src/pages/dashboard/clientela/tareas/create'))
const ClientelaTareasEditPage = lazy(() => import('src/pages/dashboard/clientela/tareas/edit'))
const ClientelaTareasPage = lazy(() => import('src/pages/dashboard/clientela/tareas/view'));
// FAMILIARES
const FamiliaresListPage = lazy(() => import('src/pages/dashboard/clientela/familiares/list'))
const FamiliaresCreatePage = lazy(() => import('src/pages/dashboard/clientela/familiares/new'))
const FamiliaresEditPage = lazy(() => import('src/pages/dashboard/clientela/familiares/edit'))
// SERVICIOS
const ServiciosListPage = lazy(() => import('src/pages/dashboard/servicios/list'));



// ----------------------------------------------------------------------

export const dashboardRoutes = [
    {
        path: 'dashboard',
        element: (
            <AuthGuard>
                <DashboardLayout>
                    <Suspense fallback={<LoadingScreen />}>
                        <Outlet />
                    </Suspense>
                </DashboardLayout>
            </AuthGuard>
        ),
        children: [
            { element: <IndexPage />, index: true },
            {
                path: 'servicios',
                children: [
                    { element: <ServiciosListPage />, index: true },
                    { path: 'list', element: <ServiciosListPage /> },
                ],
            },
            {
                path: 'personal',
                children: [
                    { element: <PersonalListPage />, index: true },
                    { path: 'list', element: <PersonalListPage /> },
                    { path: 'new', element: <PersonalCreatePage /> },
                    { path: ':id/edit', element: <PersonalEditPage /> },
                    { path: ':id/calendar', element: <PersonalCalendarPage /> },
                    { path: ':id/detail', element: <PersonalDetailPage /> },
                ],
            },
            {
                path: 'clientela',
                children: [
                    { element: <ClientelaListPage />, index: true },
                    { path: 'list', element: <ClientelaListPage /> },
                    { path: 'new', element: <ClientelaCreatePage /> },
                    { path: ':id/edit', element: <ClientelaEditPage /> },
                    { path: ':id/detail', element: <ClientelaDetailPage /> },
                    { path: ':id/calendar', element: <ClientelaCalendarPage /> },
                    { path: ':id/tareas',
                        children: [
                            { element: <ClientelaTareasPage />, index: true },
                            { path:'view', element: <ClientelaTareasPage /> },
                            { path: 'create', element: <ClientelaTareasCreatePage /> },
                            { path: 'edit', element: <ClientelaTareasEditPage /> }
                        ]
                    },
                    {
                        path: ':persona_id/familiares',
                        children: [
                            { element: <FamiliaresListPage />, index: true },
                            { path: 'list', element: <FamiliaresListPage /> },
                            { path: 'new', element: <FamiliaresCreatePage /> },
                            { path: ':id/edit', element: <FamiliaresEditPage /> }
                        ]
                    },
                ],
            },
        ],
    },
];
