// scrollbar
import 'simplebar-react/dist/simplebar.min.css';

// image
import 'react-lazy-load-image-component/src/effects/blur.css';

import { ApolloProvider } from '@apollo/client';
import { loadErrorMessages, loadDevMessages } from '@apollo/client/dev';

import apolloClient from 'src/utils/apollo';

// ----------------------------------------------------------------------

// routes
import Router from 'src/routes/sections';
// theme
import ThemeProvider from 'src/theme';
// locales
import { LocalizationProvider } from 'src/locales';

// hooks
import { useScrollToTop } from 'src/hooks/use-scroll-to-top';
// components
import ProgressBar from 'src/components/progress-bar';
import { MotionLazy } from 'src/components/animate/motion-lazy';
import SnackbarProvider from 'src/components/snackbar/snackbar-provider';
import { SettingsProvider, SettingsDrawer } from 'src/components/settings';
// auth
import { AuthProvider, AuthConsumer } from 'src/auth/context/jwt';

// ----------------------------------------------------------------------

export default function App() {
    const charAt = `

  ░░░    ░░░
  ▒▒▒▒  ▒▒▒▒
  ▒▒ ▒▒▒▒ ▒▒
  ▓▓  ▓▓  ▓▓
  ██      ██

  `;

    console.info(`%c${charAt}`, 'color: #5BE49B');

    loadDevMessages();
    loadErrorMessages();

    useScrollToTop();

    return (
        <ApolloProvider client={apolloClient}>
            <AuthProvider>
                <LocalizationProvider>
                    <SettingsProvider
                        defaultSettings={{
                            themeMode: 'light', // 'light' | 'dark'
                            themeDirection: 'ltr', //  'rtl' | 'ltr'
                            themeContrast: 'default', // 'default' | 'bold'
                            themeLayout: 'vertical', // 'vertical' | 'horizontal' | 'mini'
                            themeColorPresets: 'default', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
                            themeStretch: false,
                        }}
                    >
                        <ThemeProvider>
                            <MotionLazy>
                                <SnackbarProvider>
                                    <SettingsDrawer />
                                    <ProgressBar />
                                    <AuthConsumer>
                                        <Router />
                                    </AuthConsumer>
                                </SnackbarProvider>
                            </MotionLazy>
                        </ThemeProvider>
                    </SettingsProvider>
                </LocalizationProvider>
            </AuthProvider>
        </ApolloProvider>
    );
}
