const ROOTS = {
    AUTH: '/auth',
    DASHBOARD: '/dashboard',
};

// ----------------------------------------------------------------------

export const paths = {
    minimalUI: 'https://mui.com/store/items/minimal-dashboard/',
    about: '/sobre-nosotros',
    setPassword: '/establecer-contrasena',
    // AUTH
    auth: {
        jwt: {
            login: `${ROOTS.AUTH}/jwt/login`,
            register: `${ROOTS.AUTH}/jwt/register`,
        },
    },
    // DASHBOARD
    dashboard: {
        root: ROOTS.DASHBOARD,    
        servicios: {
            root: `${ROOTS.DASHBOARD}/servicios`,
            list: `${ROOTS.DASHBOARD}/servicios/list`,
        },
        personal: {
            root: `${ROOTS.DASHBOARD}/personal`,
            new: `${ROOTS.DASHBOARD}/personal/new`,
            list: `${ROOTS.DASHBOARD}/personal/list`,
            detail: (id) => `${ROOTS.DASHBOARD}/personal/${id}/detail`,
            edit: (id) => `${ROOTS.DASHBOARD}/personal/${id}/edit`,
            calendar: (id) => `${ROOTS.DASHBOARD}/personal/${id}/calendar`,
        },
        clientela: {
            root: `${ROOTS.DASHBOARD}/clientela`,
            new: `${ROOTS.DASHBOARD}/clientela/new`,
            list: `${ROOTS.DASHBOARD}/clientela/list`,
            detail: (id) => `${ROOTS.DASHBOARD}/clientela/${id}/detail`,
            edit: (id) => `${ROOTS.DASHBOARD}/clientela/${id}/edit`,
            calendar: (id) => `${ROOTS.DASHBOARD}/clientela/${id}/calendar`,
            tareas: {
                root: (id) => `${ROOTS.DASHBOARD}/clientela/${id}/tareas`,
                view: (id) =>`${ROOTS.DASHBOARD}/clientela/${id}/tareas/view`,
                create: (id) => `${ROOTS.DASHBOARD}/clientela/${id}/tareas/create`,
                edit: (id) => `${ROOTS.DASHBOARD}/clientela/${id}/tareas/edit`,
            },
            familiares: { 
                root: (persona_id) => `${ROOTS.DASHBOARD}/clientela/${persona_id}/familiares`,
                new: (persona_id) => `${ROOTS.DASHBOARD}/clientela/${persona_id}/familiares/new`,
                list: (persona_id) => `${ROOTS.DASHBOARD}/clientela/${persona_id}/familiares/list`,
                edit: (persona_id,id) => `${ROOTS.DASHBOARD}/clientela/${persona_id}/familiares/${id}/list`,
            }
        },
    },
};
