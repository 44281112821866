import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// layouts
import MainLayout from 'src/layouts/main';
import CompactLayout from 'src/layouts/compact';
import AuthModernCompactLayout from 'src/layouts/auth/modern-compact';
// components
import { SplashScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

export const HomePage = lazy(() => import('src/pages/home'));
const Page500 = lazy(() => import('src/pages/500'));
const Page403 = lazy(() => import('src/pages/403'));
const Page404 = lazy(() => import('src/pages/404'));
const EstablecerContrasena = lazy(() => import('src/pages/establecer-contrasena'));
const AboutPage = lazy(() => import('src/pages/sobre-nosotros'));

// ----------------------------------------------------------------------

export const mainRoutes = [
    {
        element: (
            <MainLayout>
                <Suspense fallback={<SplashScreen />}>
                    <Outlet />
                </Suspense>
            </MainLayout>
        ),
        children: [
            { path: 'sobre-nosotros', element: <AboutPage /> },
        ]
    },
    {
        element: (
            <CompactLayout>
                <Suspense fallback={<SplashScreen />}>
                    <Outlet />
                </Suspense>
            </CompactLayout>
        ),
        children: [
            { path: '500', element: <Page500 /> },
            { path: '404', element: <Page404 /> },
            { path: '403', element: <Page403 /> },
        ],
    },
    {
        element: (
            <AuthModernCompactLayout>
                <Outlet />
            </AuthModernCompactLayout>
        ),
        children: [
            { path: 'establecer-contrasena', element: <EstablecerContrasena /> }
        ],
    },
];
