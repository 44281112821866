import PropTypes from 'prop-types';
// @mui
import { alpha, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// theme
import { bgGradient } from 'src/theme/css';
// components
import Logo from 'src/components/logo';
import SponsorsMobile from 'src/components/sponsors-mobile';
import Sponsors from 'src/components/sponsors';

// ----------------------------------------------------------------------

export default function AuthClassicLayout({ children, image, title }) {

    const theme = useTheme();

    const upMd = useResponsive('up', 'md');

    const renderLogo = (
        <Logo
            sx={{
                zIndex: 9,
                position: 'absolute',
                m: { xs: 2, md: 5 },
            }}
        />
    );

    const renderSponsorsMobile =  (
        <SponsorsMobile/>
    );

    const renderContent = (
        <Stack
            sx={{
                width: 1,
                mx: 'auto',
                maxWidth: 480,
                px: { xs: 2, md: 8 },
                py: { xs: 15, md: 30 },
            }}
        >      
            {children}
            {!upMd && renderSponsorsMobile}
        </Stack>
    );

    const renderSection = (
        <Stack
            flexGrow={1}
            alignItems="center"
            justifyContent="center"
            spacing={10}
            sx={{
                ...bgGradient({
                    color: alpha(
                        theme.palette.background.default,
                        theme.palette.mode === 'light' ? 0.88 : 0.94
                    ),
                    imgUrl: '/assets/background/overlay_2.jpg',
                }),
            }}
        >            

            <Box
                component="img"
                alt="auth"
                src={image || '/assets/illustrations/apoyo-discapacidad@720x441.png'}
                sx={{ maxWidth: 720 }}
            />

            <Sponsors/>
        </Stack>
    );

    return (
        <Stack
            component="main"
            direction="row"
            sx={{
                minHeight: '100vh',
            }}
        >
            {renderLogo}

            {upMd && renderSection}

            {renderContent}
        </Stack>
    );
}

AuthClassicLayout.propTypes = {
    children: PropTypes.node,
    image: PropTypes.string,
    title: PropTypes.string,
};
