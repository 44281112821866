import React from 'react';
import { Stack, Box, Link } from '@mui/material';

const SponsorsMobile = () => (
    <Stack spacing={3} sx={{ mt: 3 }}>
        {/* Fila 1 */}
        <Box display="flex" justifyContent="flex-start">
            <Link href="https://cecoec.es/" underline="none" target="_blank" rel="noopener noreferrer">
                <Box
                    display="flex"
                    component="img"
                    src="/assets/logos/logo-cecoec.svg"
                    alt="Logotipo CECOEC"
                    sx={{
                        width: 150,
                        height: 48,
                        cursor: 'pointer',
                    }}
                />
            </Link>
        </Box>

        {/* Fila 2 */}
        <Stack
            direction="column"
            spacing={2}
            sx={{
                borderTop: '1px solid #ccc', // Borde superior
                borderBottom: '1px solid #ccc', // Borde inferior
                py: 1, // Espacio vertical para que el contenido no esté demasiado pegado a los bordes
            }}
        >
            {/* Fila 2.1 */}
            <Stack direction="row" justifyContent="space-between" spacing={3}>
                <Link
                    href="https://next-generation-eu.europa.eu/index_es"
                    underline="none"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <Box
                        component="img"
                        src="/assets/logos/logo-next-generation.svg"
                        alt="Logotipo Next Generation UE"
                        sx={{
                            width: { xs: 100, md: 120 },
                            maxWidth: '100%',
                            height: 38,
                            cursor: 'pointer',
                        }}
                    />
                </Link>
                <Link
                    href="https://planderecuperacion.gob.es/"
                    underline="none"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <Box
                        component="img"
                        src="/assets/logos/logo-plan-recuperacion.svg"
                        alt="Logotipo Plan de Recuperación y Resiliencia Gobierno de España"
                        sx={{
                            width: { xs: 100, md: 120 },
                            maxWidth: '100%',
                            height: 38,
                            cursor: 'pointer',
                        }}
                    />
                </Link>
                <Link
                    href="https://www.mdsocialesa2030.gob.es/index.htm"
                    underline="none"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <Box
                        component="img"
                        src="/assets/logos/logo-ministerio-asuntos-sociales.svg"
                        alt="Logotipo Ministerio de Derechos Sociales y Agenda 2030"
                        sx={{
                            width: { xs: 100, md: 120 },
                            maxWidth: '100%',
                            height: 38,
                            cursor: 'pointer',
                        }}
                    />
                </Link>
            </Stack>

            {/* Fila 2.2 */}
            <Stack direction="row" justifyContent="flex-end" spacing={3}>
                <Link
                    href="https://socialasturias.asturias.es/"
                    underline="none"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <Box
                        component="img"
                        src="/assets/logos/logo-consejeria-asuntos-sociales.svg"
                        alt="Logotipo Consejería Derechos Sociales y Bienestar"
                        sx={{
                            width: { xs: 100, md: 120 },
                            maxWidth: '100%',
                            height: 38,
                            cursor: 'pointer',
                        }}
                    />
                </Link>
                <Box
                    component="img"
                    src="/assets/logos/logo-camaras-asturias.svg"
                    alt="Logotipo Camaras de Asturias"
                    sx={{
                        width: { xs: 100, md: 120 },
                        maxWidth: '100%',
                        height: 38,
                        cursor: 'pointer',
                    }}
                />
            </Stack>
        </Stack>
    </Stack>
);

export default SponsorsMobile;
